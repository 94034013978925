import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private http: HttpClient) {}

  statsEndpoint = 'stats';

  getScansTodayCount(shopId: number) {
    return this.http.get(
      environment.apiUrl +
        this.statsEndpoint +
        '/' +
        shopId +
        '/scans/today/count'
    );
  }

  getGenderRatio(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.statsEndpoint + '/' + shopId + '/gender'
    );
  }

  getAgeRatio(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.statsEndpoint + '/' + shopId + '/age'
    );
  }

  getClientTypeRatio(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.statsEndpoint + '/' + shopId + '/client'
    );
  }

  getClients(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.statsEndpoint + '/' + shopId + '/client/list'
    );
  }
}
