import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  userEndpoint = 'users';

  getUsers() {
    return this.http.get(environment.apiUrl + this.userEndpoint);
  }

  getEmployees(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.userEndpoint + '/myemployees/' + shopId
    );
  }

  getActiveUsersCount() {
    return this.http.get(
      environment.apiUrl + this.userEndpoint + '/active/count'
    );
  }

  createEmployee(formData: any) {
    let object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    const json = JSON.stringify(object);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(
      environment.apiUrl + this.userEndpoint + '/employee/activate',
      json,
      httpOptions
    );
  }

  deleteEmployee(userId: number, shopId: number) {
    return this.http.delete(
      environment.apiUrl +
        this.userEndpoint +
        '/employee/' +
        userId +
        '/shop/' +
        shopId
    );
  }
}
